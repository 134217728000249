<template>
  <PageItem class="no-margin-top">
    <ion-list class="events">
      <ion-list-header>
        {{ $t('youreventtickets') }}
      </ion-list-header>

      <div v-if="!$apollo.loading">
        <ion-item v-for="event in getTicketsForEvent" :key="event.id" button @click="openTicketCheckin(event.id)">
          <ion-label>
            <h2>{{ event.title }}</h2>
            <ion-text color="primary">
              <span class="text-sm block">{{ event.held_at|formatDateTime }}</span>
            </ion-text>
          </ion-label>
          <ion-badge slot="end" color="primary">
            {{ event.attendee_ticket_count }}
          </ion-badge>
        </ion-item>
      </div>
    </ion-list>
  </PageItem>
</template>

<script>
    import {GET_ORDERED_EVENTS} from "@/graphql/queries";
    import PageItem from "@/components/PageItem";

    export default {
      components: {
        PageItem
      },
      apollo: {
        getTicketsForEvent: {
          query: GET_ORDERED_EVENTS
        }
      },
      methods: {
        openTicketCheckin(id) {
          this.$router.push({ name: this.$routeNames.TICKET_CHECKIN, params: { id }})
        }
      }
    }
</script>

<style scoped>
    ion-list.events {
        background: #fff;
    }

    ion-list.events ion-item {
        --background: var(--ion-color-white);
    }
</style>
