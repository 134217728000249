<template>
  <ion-page>
    <GoBackHeader defaultHref="/events">
      <ion-segment class="top" scrollable @ionChange="segmentChanged($event)">
        <ion-segment-button class="rider" value="rider" :checked="tab == 'rider'">
          <ion-label>{{ $t('qrcode') }}</ion-label>
        </ion-segment-button>

        <ion-segment-button v-if="user && user.facilities && user.facilities.length" value="organization" :checked="tab == 'organization'">
          <ion-label>{{ $t('manage') }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </GoBackHeader>

    <ion-content>
      <div v-if="tab === 'rider'">
        <PageItem class="no-margin-top">
          <ion-grid class="px-0">
            <ion-row>
              <ion-col class="pl-0">
                <h1>
                  <ion-text color="moto">
                    {{ $t('personalqrcode') }}
                  </ion-text>
                </h1>
                <h2>{{ user.first_name }} {{ user.last_name }}</h2>
              </ion-col>
            </ion-row>
          </ion-grid>
        </PageItem>

        <PageItem>
          <qrcode-vue
            v-if="user"
            :value="user.qr_code"
            :size="size"
            level="H"
            class="mt-2 ml-2 flex justify-center qr-code-rider"
          />
        </PageItem>
        <PageItem>
          <div class="mt-2 ml-2 flex justify-center">
            <AdvertisementSlides :style="{width: size + 'px'}" ad-slot="qr_page" />
          </div>
        </PageItem>
      </div>

      <div v-if="tab === 'tickets'">
        <Tickets />
      </div>

      <div v-if="tab === 'registration'">
        <ion-list class="events">
          <ion-list-header>
            {{ $t('registrations') }}
          </ion-list-header>

          <ion-item :href="'/test'">
            <ion-label>
              <h2>GP Lommel </h2>
            </ion-label>
            <ion-badge slot="end" color="primary">
              4
            </ion-badge>
          </ion-item>
        </ion-list>
      </div>

      <div v-if="tab === 'organization'">
        <Organization />
      </div>
    </ion-content>

    <ion-footer v-if="tab === 'rider' && isNativePlatform()">
      <ion-toolbar>
        <ion-button 
          expand="full" 
          color="moto"
          @touchstart="enableBrightness"
          @touchend="resetBrightness"
          @mousedown="enableBrightness"
          @mouseup="resetBrightness"
        >
          <ion-icon slot="start" name="sunny" color="tertiary" />
          {{ $t('brightenscreen') }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'
    import PageItem from '@/components/PageItem'
    import Tickets from '@/views/checkin/Tickets'
    import Organization from '@/views/checkin/Organization'
    import GoBackHeader from '@/components/navigation/GoBackHeader'
    import { mapGetters } from "vuex";
    import { CURRENT_USER_GETTER } from "@/store/store-getters";
    import { Capacitor } from '@capacitor/core';
    const { isNativePlatform } = Capacitor;

    import { ScreenBrightness } from '@capacitor-community/screen-brightness';
    import AdvertisementSlides from "../../components/AdvertisementSlides";

    export default {
      components: {
        AdvertisementSlides,
        QrcodeVue,
        PageItem,
        Tickets,
        Organization,
        GoBackHeader
      },
      data() {
        return {
          size: 250,
          tab: 'rider',
          eventTab: 'all',
          initialBrightness: null,
          currentBrightness: null,
        }
      },
      computed: {
        ...mapGetters({user: CURRENT_USER_GETTER}),
      },
      mounted() {
        this.setInitialBrightness();
      },
      methods: {
        isNativePlatform,
        async setInitialBrightness() {
          // Brightness is only supported on native
          if (this.isNativePlatform()) {
            this.initialBrightness = await ScreenBrightness.getBrightness();
          }
        },
        async enableBrightness() {
          await ScreenBrightness.setBrightness({ brightness: 1 });
        },
        async resetBrightness() {
          await ScreenBrightness.setBrightness(this.initialBrightness);
        },
        segmentChanged(event) {
          this.tab = event.target.value;
        },
        changeEventSegment(event) {
          this.eventTab = event.target.value;
        }
      }
    }
</script>

<style>
.qr-code-rider canvas {
  border: 20px solid #fff;
}
</style>
